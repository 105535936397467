import { Helmet } from "react-helmet";

const TermsOfUse = () => {
    return (
        <div className="container">
            <Helmet>
                <title>Terms of Use</title>
            </Helmet>
            <h2 className="data-header"><b>Terms of Use</b></h2>
            <p className="we-keep-header"><b>Introduction</b></p>
            <p className="we-keep-header">These Terms of Use ("Terms") govern your use of Connected Newsroom. By using our platform,
                you agree to comply with these Terms. If you do not agree, please do not use the application.</p>
            <p className="we-keep-header"><b>1. User Responsibilities</b></p>
            <p className="we-keep-header"><b>1.1 Registration</b></p>
            <p className="we-keep-header">To access features, journalists and experts must register an account. Users must provide
                accurate information during registration and update details as needed.</p>

            <p className="we-keep-header"><b>1.2 Responsible Use</b></p>
            <p className="we-keep-header">Users may not use the platform for illegal, defamatory, or harmful purposes.
                Experts can respond to journalist queries and submit leaks, which may include sensitive information. Leaks that are knowingly false,
                defamatory, or illegal are prohibited.
                Journalists must adhere to ethical reporting standards and cannot disclose an expert’s identity if they submitted a response
                anonymously.</p>

            <p className="we-keep-header"><b>1.3 Prohibited Activities</b></p>
            <p className="we-keep-header">Users must not:</p>
            <p className="we-keep-header">Impersonate others or submit false information<br></br>
                Post harmful, illegal, or defamatory content<br></br>
                Violate the privacy rights of other users</p>

            <p className="we-keep-header"><b>2 Anonymity and Confidentiality</b></p>
            <p className="we-keep-header">Experts have the option to remain anonymous when responding to queries.
                Connected Newsroom will protect the anonymity of experts as outlined in the Privacy Policy,
                but anonymity cannot be guaranteed if users share information outside the platform.</p>

            <p className="we-keep-header"><b>3 Intellectual Property</b></p>
            <p className="we-keep-header">Connected Newsroom owns all intellectual property rights to the platform, including software,
                trademarks, logos, and content created by the company. Users retain ownership of the content they create, such as queries and responses,
                but grant Connected Newsroom a license to use, display, and share this content as needed for the platform’s operation.</p>

            <p className="we-keep-header"><b>4 Content Moderation</b></p>
            <p className="we-keep-header">Connected Newsroom reserves the right to moderate, edit, or remove content that
                violates these Terms or applicable laws. We are not responsible for user content
                and do not endorse the accuracy or reliability of information submitted by users.</p>
            <p className="we-keep-header"><b>5 Limitation of Liability</b></p>
            <p className="we-keep-header">Connected Newsroom is not liable for any damages resulting from:</p>
            <p className="we-keep-header">The accuracy, reliability, or legality of content shared on the platform
                Unauthorized access to or alteration of user data
                Any interruptions, security breaches, or errors in the platform<br></br></p>

            <p className="we-keep-header"><b>6 Account Termination</b></p>
            <p className="we-keep-header">We reserve the right to suspend or terminate any user account that violates these Terms or engages in prohibited activities, with or without notice.</p>
            <p className="we-keep-header"><b>7. Changes to Terms of Use</b></p>
            <p className="we-keep-header">Connected Newsroom may update these Terms at its discretion. Users will be notified of significant changes,
                and continued use of the platform after changes constitutes acceptance of the revised Terms.</p>
            <p className="we-keep-header mt-5"><b>Contact Us</b></p>
            <p className="we-keep-header">For any questions about this Privacy Policy, please contact us at </p>
            <p className="we-keep-header">info@yourconnectednewsroom.com</p>





        </div>
    )
}

export default TermsOfUse;