import { Helmet } from "react-helmet";

const DataPrivacyTab = () => {
    return (
        <div className="container">
            <Helmet>
                <title>Data Privacy</title>
            </Helmet>
            <h2 className="data-header"><b>Privacy Policy</b></h2>
            <p className="we-keep-header"><b>Introduction</b></p>
            <p className="we-keep-header">Connected Newsroom ("we," "us," "our") respects the privacy of our users, including journalists and
                experts who use our platform to share and request information. This Privacy Policy explains how we collect, use, disclose, and safeguard
                your information
                when you use our application. Please read this policy carefully to understand our practices regarding your
                information and how we treat it.</p>
            <p className="we-keep-header"><b>1. Information We Collect</b></p>
            <p className="we-keep-header"><b>1.1 Personal Information</b></p>
            <p className="we-keep-header">We may collect personal information from users, including but not limited to:</p>
            <p className="we-keep-header">Name, email address, and professional information during registration
                Details provided in profiles, including credentials and areas of expertise</p>

            <p className="we-keep-header"><b>1.2 Non-Personal Information</b></p>
            <p className="we-keep-header">We also collect non-personal information, such as usage data and analytics, to improve platform functionality and user experience.</p>

            <p className="we-keep-header"><b>1.3 Information Shared Between Journalists and Experts</b></p>
            <p className="we-keep-header">Experts can respond to queries from journalists and submit information or documents. Some responses can be submitted anonymously, depending on the expert’s choice. Journalists don't know the identities of experts in anonymous cases.</p>

            <p className="we-keep-header"><b>2 Use of Information</b></p>
            <p className="we-keep-header">We use the information collected to:</p>
            <p className="we-keep-header">Facilitate communication between journalists and experts<br></br>
                Improve and customize user experience<br></br>
                Ensure platform security and compliance<br></br>
                Provide customer support</p>
            <p className="we-keep-header"><b>3 Disclosure of Information</b></p>
            <p className="we-keep-header">We may share information in the following cases:</p>
            <p className="we-keep-header">With third-party service providers for operational purposes (e.g., payment processing)
                When required by law or in response to valid <br></br>legal processes
                In cases of suspected fraud, threats to security, or to protect the rights and property of Connected Newsroom</p>
            <p className="we-keep-header"><b>4 Data Security</b></p>
            <p className="we-keep-header">We implement reasonable measures to protect your personal data from unauthorized access, alteration, and destruction.</p>
            <p className="we-keep-header"><b>5 User Choices and Rights</b></p>
            <p className="we-keep-header">Depending on applicable laws, users may have rights such as:</p>
            <p className="we-keep-header">Accessing or correcting personal data<br></br></p>
            <p className="we-keep-header">Deleting personal information<br></br></p>
            <p className="we-keep-header">Limiting certain uses of personal data<br></br></p>
            <p className="we-keep-header"><b>6 Data Retention</b></p>
            <p className="we-keep-header">We retain user data only as long as necessary for the purposes outlined in this Privacy Policy or as required by law.</p>
            <p className="we-keep-header"><b>7. Changes to Privacy Policy</b></p>
            <p className="we-keep-header">We may update this Privacy Policy periodically. We encourage you to review it regularly to stay informed.</p>
            <p className="we-keep-header mt-5"><b>Contact Us</b></p>
            <p className="we-keep-header">For any questions about this Privacy Policy, please contact us at </p>
            <p className="we-keep-header">info@yourconnectednewsroom.com</p>



            {/* <h2 className="data-header">Data Tracking</h2>
            <p className="we-keep-header">Access your data and learn about what data we keep.</p> */}
        </div>
    )
}

export default DataPrivacyTab;